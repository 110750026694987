import React, { useCallback, useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Item, Anchor, Button, Box } from "../../components/elements";
import { CardLayout, CardHeader, FloatCard } from "../../components/cards";
import { Breadcrumb, Pagination } from "../../components";
import AdminTable from "../../components/tables/AdminTable";
import LabelField from "../../components/fields/LabelField";
import PageLayout from "../../layouts/PageLayout";
import data from "../../data/master/adminList.json";
import instance from "../../api/axios";

export default function ForgotPassword() {
  const [adminInfo, setAdminInfo] = useState({
    consultants: 0,
    receptionists: 0,
    active: 0,
    inactive: 0,
  });

  const [allAdmin, setallAdmin] = useState(null);
  const [equery, setEquery] = useState("");

  const [allfilterData, setAllfilterData] = useState(null);

  async function admins() {
    const response = await instance({
      url: "/auth/get-all-admin",
      method: "GET",
    });
    
    setallAdmin(response.data);
  }

  const search = () => {
    if (equery != "" || equery != null) {
      const filterarray = allAdmin?.filter((e) => {
        if (e.email.toLowerCase().includes(equery.toLowerCase())) {
          return e;
        }
      });
      setAllfilterData(filterarray);
    }
  };

  async function getCountByRole() {
    const response = await instance({
      url: "/auth/get-admin-by-position",
      method: "GET",
    });
   
    setAdminInfo({
      consultants: response.data.consultants,
      receptionists: response.data.receptionists,
      active: response.data.active,
      inactive: response.data.inactive,
    });
  }
  useEffect(() => {
    admins();
    getCountByRole();
    search();
  }, [equery]);

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <CardLayout>
            <Breadcrumb title={data?.pageTitle}>
              {data?.breadcrumb.map((item, index) => (
                <Item key={index} className="mc-breadcrumb-item">
                  {item.path ? (
                    <Anchor className="mc-breadcrumb-link" href={item.path}>
                      {item.text}
                    </Anchor>
                  ) : (
                    item.text
                  )}
                </Item>
              ))}
            </Breadcrumb>
          </CardLayout>
        </Col>
        {/* <Col xl={4}>
          <FloatCard
            style={{ backgroundColor: "#7BD0FE" }}
            color={"#fff"}
            variant="lg"
            digit={adminInfo?.consultants}
            title="Total Consultant(s)"
            icon="people_outline"
          />
        </Col> */}
        {/* <Col xl={4}>
          <FloatCard
            style={{ backgroundColor: "#007FCB" }}
            color={"#fff"}
            variant="lg"
            digit={adminInfo?.receptionists}
            title="Total Receptionist(s)"
            icon="people_outline"
          />
        </Col> */}
        {/* <Col xl={4}>
          <FloatCard
            style={{ backgroundColor: "#34B8FC" }}
            color={"#fff"}
            variant="lg"
            digit={adminInfo?.inactive}
            title="Total Inactive Employee(s)"
            icon="people_outline"
          />
        </Col> */}

        <Col xl={12}>
          <CardLayout>
            <Row>
              <Col xl={10}>
                {" "}
                {/* <CardHeader title="all employees" /> */}
              </Col>
              
              <Col xl={2}>
                {/* <Anchor
                  className="mc-breadcrumb-link d-flex flex-row-reverse"
                  href="/registeradmin"
                >
                  Add
                </Anchor> */}
              </Col>
            </Row>

            <Row xs={1} sm={4} className="mb-4">
              <Col xl={8}></Col>

              {/* {data?.filter.map((item, index) => (
                <Col xl={4} sm={12} key={index}>
                  <LabelField
                    type={item.type}
                    label={item.label}
                    option={item.option}
                    placeholder={item.placeholder}
                    labelDir="label-col"
                    fieldSize="w-100 h-md"
                    onChange={(e) => setEquery(e.target.value)}
                  />
                </Col>
              ))} */}
            </Row>
            <AdminTable
              thead={data?.table.thead}
              tbody={equery ? allfilterData : allAdmin}
            />
       {/* <Pagination /> */}          </CardLayout>
        </Col>
      </Row>
    </PageLayout>
  );
}
