import { ThemeProvider } from "./context/Themes";
import { LoaderProvider } from "./context/Preloader";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Overview, Documentation, ChangeLog, Error } from "./pages/supports";
import {
  Avatars,
  Alerts,
  Buttons,
  Charts,
  Tables,
  Fields,
  Headings,
  Colors,
} from "./pages/blocks";
import {
  Ecommerce,
  Analytics,
  CRM,
  ForgotPassword,
  Register,
  Login,
  UserList,
  UserProfile,
  MyAccount,
  ProductList,
  ProductView,
  ProductUpload,
  InvoiceList,
  InvoiceDetails,
  OrderList,

  Notification,
  BlankPage,
  Settings,
} from "./pages/master";
import EditMyProfile from "./pages/master/Bin/EditMyProfile";
import RegisterAdmin from "./pages/master/Bin/RegisterAdmin";
import PracticeArea from "./pages/master/Bin/PracticeArea";
import EditPracticeArea from "./pages/master/Bin/EditPracticeArea";
import AddPractice from "./pages/master/Bin/AddPractice";
import BlogSection from "./pages/master/Bin/BlogSection";
import AddBlog from "./pages/master/Bin/AddBlog";
import EditBlog from "./pages/master/Bin/EditBlog";
import EventSection from "./pages/master/Bin/EventSection";
import AddEvent from "./pages/master/Bin/AddEvent";
import EditEvent from "./pages/master/Bin/EditEvent";
import OurTeam from "./pages/master/Bin/OurTeam";
import EditAbout from "./pages/master/Bin/CMS/About/EditAbout";
import Message from "./pages/master/Bin/CEO/Message";

import AddTeam from "./pages/master/Bin/AddTeam";
import EditTeam from "./pages/master/Bin/EditTeam";
// frontend
// import { AboutUs } from "../src/frontend/src/Pages/AboutUs";
// import ClientOurTeam from "./frontend/src/Pages/ClientOurTeam";
// import ClientPracticeArea from "./frontend/src/Pages/ClientPracticeArea";
// import ClientBlog from "./frontend/src/Pages/ClientBlog";
// import Home from "./frontend/src/Pages/Home";
// import ContactUs from "./frontend/src/Pages/ContactUs";
// import BlogView from "./frontend/src/Pages/ClientBlogModal";
import Leads from "./pages/master/Bin/Leads";
import ExistingClient from "./pages/master/Bin/ ExistingClient";
import NewClientProfile from "./pages/master/Bin/NewClientProfile";
import FullClientCases from "./pages/master/Bin/FullClientProfile";
import RegisterUser from "./pages/master/Bin/RegisterUser"
import Protected from "./Protected";
import ExistingClientProfile from "./pages/master/Bin/ExistingClientProfile";
import Contact from "./pages/master/Bin/Contact";
import ContactProfile from "./pages/master/Bin/ContactProfile";
import Certificate from "./pages/master/Bin/Certificate";
import CertificateView from "./pages/master/Bin/CertificateView";
import AddCertificate from "./pages/master/Bin/AddCertificate";
import TodayConsultant from "./pages/master/Bin/TodayConsultant";
import AddTodayConsultant from "./pages/master/Bin/AddTodayConsultant";
import { adminLogout } from "./api/adminlogout";
import { useEffect } from "react";
import PageIntro from "./pages/master/Bin/CMS/Intro/PageIntro";
import Subscribe from "./pages/master/Bin/CMS/Subscriber/Subscribe";


export default function App() {
  setTimeout(() => { adminLogout() }, 120*60*1000)

  return (
    <ThemeProvider>
      <LoaderProvider>
        <BrowserRouter>
          <Routes>
            {/* master Pages */}
            {/* <Route path="/ecommerce" element={<Ecommerce />} />
            <Route path="/analytics" element={<Analytics />} />
            <Route path="/crm" element={<CRM />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/user-list" element={<UserList />} />
            <Route path="/user-profile" element={<UserProfile />} />
            <Route path="/my-account" element={<MyAccount />} />
            <Route path="/product-list" element={<ProductList />} />
            <Route path="/product-view" element={<ProductView />} />
            <Route path="/product-upload" element={<ProductUpload />} />
            <Route path="/invoice-list" element={<InvoiceList />} />
            <Route path="/invoice-details" element={<InvoiceDetails />} />
            <Route path="/order-list" element={<OrderList />} />
            <Route path="/message" element={<Message />} />
            <Route path="/notification" element={<Notification />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/blank-page" element={<BlankPage />} /> */}
            {/* Bin */}
            <Route path="/login" element={<Login />} />

            <Route
              path="/active-admin"
              element={
                <Protected>
                  <ForgotPassword />
                </Protected>
              }
            />

            <Route
              path="/editmyacount"
              element={
                <Protected>
                  <EditMyProfile />
                </Protected>
              }
            />
            <Route
              path="/registeradmin"
              element={
                <Protected>
                  <RegisterAdmin />
                </Protected>
              }
            />
            <Route
              path="/practicelist"
              element={
                <Protected>
                  <PracticeArea />
                </Protected>
              }
            />
            <Route
              path="/editpracticearea"
              element={
                <Protected>
                  <EditPracticeArea />
                </Protected>
              }
            />
            <Route
              path="/addpractices"
              element={
                <Protected>
                  <AddPractice />
                </Protected>
              }
            />
            <Route
              path="/blogsection"
              element={
                <Protected>
                  <BlogSection />
                </Protected>
              }
            />
            <Route
              path="/addblog"
              element={
                <Protected>
                  <AddBlog />
                </Protected>
              }
            />
            <Route
              path="/editblog"
              element={
                <Protected>
                  <EditBlog />
                </Protected>
              }
            />
            <Route
              path="/eventsection"
              element={
                <Protected>
                  <EventSection />
                </Protected>
              }
            />
            <Route
              path="/addevent"
              element={
                <Protected>
                  <AddEvent />
                </Protected>
              }
            />
            <Route
              path="/editevent"
              element={
                <Protected>
                  <EditEvent />
                </Protected>
              }
            />
            <Route
              path="/edit-cms-about"
              element={
                <Protected>
                  <EditAbout />
                </Protected>
              }
            />
            <Route
              path="/ourteams"
              element={
                <Protected>
                  <OurTeam />
                </Protected>
              }
            />
            <Route
              path="/add-team-member"
              element={
                <Protected>
                  <AddTeam />
                </Protected>
              }
            />
            <Route
              path="/edit-team-member"
              element={
                <Protected>
                  <EditTeam />
                </Protected>
              }
            />

            <Route path="/leads-section" element={<Protected><Leads /></Protected>} />
            <Route
              path="/existing-leads-section"
              element={
                <Protected>
                  <ExistingClient />
                </Protected>
              }
            />
             <Route
              path="/register-section"
              element={
                <Protected>
                  <RegisterUser />
                </Protected>
              }
            />
            <Route
              path="/new-client-profile"
              element={
                <Protected>
                  <NewClientProfile />
                </Protected>
              }
            />
             <Route
              path="/full-client-Cases"
              element={
                <Protected>
                  <FullClientCases/>
                </Protected>
              }
            />
            <Route
              path="/existing-client-profile"
              element={
                <Protected>
                  <ExistingClientProfile />
                </Protected>
              }
            />
            <Route
              path="/user-contact"
              element={
                <Protected>
                  <Contact />
                </Protected>
              }
            />
            <Route
              path="/user-contact-profile"
              element={
                <Protected>
                  <ContactProfile />
                </Protected>
              }
            />
            <Route
              path="/ceo-message"
              element={
                <Protected>
                  <Message />
                </Protected>
              }
            />
            <Route
              path="/awards-certifications"
              element={
                <Protected>
                  <Certificate />
                </Protected>
              }
            />
            {/* <Route
              path="/certificate-view"
              element={
                <Protected>
                  <CertificateView />
                </Protected>
              }
            /> */}
            <Route
              path="/add-certificate"
              element={
                <Protected>
                  <AddCertificate />
                </Protected>
              }
            />

            <Route
              path="/today-consultant"
              element={
                <Protected>
                  <TodayConsultant />
                </Protected>
              }
            />

<Route
              path="/page-intro"
              element={
                <Protected>
                  <PageIntro/>
                </Protected>
              }
            />

<Route
              path="/subscriber"
              element={
                <Protected>
                  <Subscribe />
                </Protected>
              }
            />

              {/* <Route
              path="/add-today-consultant"
              element={
                <Protected>
                  <AddTodayConsultant />
                </Protected>
              }
            /> */}

            {/* //Frontend */}
            {/* <Route path="v1/client/about-us" element={<AboutUs />} />
            <Route path="v1/client/our-team" element={<ClientOurTeam />} />
            <Route path="v1/client/our-team" element={<ClientOurTeam />} />
            <Route
              path="v1/client/practice-area"
              element={<ClientPracticeArea />}
            /> */}
            {/* <Route path="v1/client/blog-section" element={<ClientBlog />} /> */}
            {/* <Route path="v1/client/home-section" element={<Home />} /> */}
            {/* <Route path="v1/client/contact-us" element={<ContactUs />} />
            <Route path="v1/client/blog-view" element={<BlogView />} /> */}


            {/* Blocks Pages */}
            {/* <Route path="/headings" element={<Headings />} />
            <Route path="/buttons" element={<Buttons />} />
            <Route path="/avatars" element={<Avatars />} />
            <Route path="/colors" element={<Colors />} />
            <Route path="/charts" element={<Charts />} />
            <Route path="/tables" element={<Tables />} />
            <Route path="/fields" element={<Fields />} />
            <Route path="/alerts" element={<Alerts />} /> */}

            {/* Supports Pages */}
            <Route path="*" element={<Error />} />
            <Route path="/" element={
              <Protected>
                <Navigate to="/leads-section" />
              </Protected>
            } />
            <Route path="/documentation" element={<Documentation />} />
            <Route path="/changelog" element={<ChangeLog />} />
          </Routes>
        </BrowserRouter>
      </LoaderProvider>
    </ThemeProvider>
  );
}
