// import React, { useCallback, useEffect, useState } from "react";
// import { Row, Col } from "react-bootstrap";
// import { Anchor, Item } from "../../../components/elements";
// import { CardLayout, CardHeader, FloatCard } from "../../../components/cards";
// import { Breadcrumb, Pagination } from "../../../components";
// import LabelField from "../../../components/fields/LabelField";
// import PageLayout from "../../../layouts/PageLayout";
// import data from "../../../data/master/Bin/fullClientCases.json";
// import instance from "../../../api/axios";
// import FullClientTable from "../../../components/tables/Bin/FullClientTable";
// import { getClientData } from "../../../api/endpoints"
// import { useQuery } from "../../../api/query"


// export default function FullClientProfile() {
//   const [allUserData, setAllUserData] = useState([])
//   const query = useQuery();
//   const [allfilterData, setAllfilterData] = useState(null);
//   const search = () => {
//     if (query != "" || query != null) {
//       const filterarray = allUserData?.filter((e) => {
//         if (e?.email?.toLowerCase().includes(query.toLowerCase())) {
//           return e;
//         }
//       });
//       setAllfilterData(filterarray);
//     }
//   };
  
//   const getClientInfo = async () => {
//     try {
//       await instance({
//         url: `${getClientData}/${query.get("query_id")}`,
//         method: "GET",
//       },).then((res) => {

//         if (sessionStorage.getItem("admin_role_validation") == "PA") {
//           res.data.map((e) => {
//             if (e.consultant == "ceo") {
//               setAllUserData((pre) => [...pre, e])
//             }
//           })
//         }else if(sessionStorage.getItem("admin_role_validation") == "HR" || sessionStorage.getItem("admin_role_validation") == "Receptionist"){
//           res.data.map((e) => {
//             if (e.consultant != "ceo") {
//               setAllUserData((pre) => [...pre, e])
//             }
//           })  
//         }
//         else if(sessionStorage.getItem("admin_role_validation") == "Consultant" || sessionStorage.getItem("admin_role_validation") == "Receptionist"){
//           res.data.map((e) => {
//             if (e.consultant?.toLowerCase().trim()==sessionStorage.getItem("admin_name_validation")?.toLocaleLowerCase().trim()) {
//               setAllUserData((pre) => [...pre, e])
//             }
//           })  
//         }
//         else{
//           setAllUserData(res.data.data)
//         }
//       });
//     } catch (e) {
//       console.error(e);
//     }
//   }
//   useEffect(() => {
//     getClientInfo()
//     search()
//   }, [query])

//   return (
//     <PageLayout>
//       <Row>
//         <Col xl={12}>
//           <CardLayout>
//             <Breadcrumb title={data?.pageTitle}>
//               {/* {data?.breadcrumb.map((item, index) => (
//                 <Item key={index} className="mc-breadcrumb-item">
//                   {item.path ? (
//                     <Anchor className="mc-breadcrumb-link" href={item.path}>
//                       {item.text}
//                     </Anchor>
//                   ) : (
//                     item.text
//                   )}
//                 </Item>
//               ))} */}
//             </Breadcrumb>
//           </CardLayout>
//         </Col>

//         <Col xl={12}>
//           <CardLayout>
//             <Row>
//               <Col xl={10}>
//                 {" "}
//                 {/* <CardHeader title="New Client Listings" /> */}
//               </Col>
//               <Col xl={2}>
//               </Col>
//             </Row>

//             <Row xs={1} sm={4} className="mb-4">
//               <Col xl={8} sm={0}>

//               </Col>
//               {/* {data?.filter.map((item, index) => (
//                 <Col xl={4} sm={12} key={index}>
//                   <LabelField
//                     type={item.type}
//                     label={item.label}
//                     option={item.option}
//                     placeholder={item.placeholder}
//                     labelDir="label-col"
//                     fieldSize="w-100 h-sm"
//                     onChange={(e)=> setQuery(e.target.value)}
                    
//                   />
//                 </Col>
//               ))} */}
//             </Row>
//             <FullClientTable
//               thead={data?.table.thead}
//               tbody={query ? allfilterData : allUserData}
//             />

//             {/* <Pagination /> */}          </CardLayout>
//         </Col>
//       </Row>

//     </PageLayout>
//   );
// }


import React, { useCallback, useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Anchor, Item } from "../../../components/elements";
import { CardLayout, CardHeader, FloatCard } from "../../../components/cards";
import { Breadcrumb, Pagination } from "../../../components";
import LabelField from "../../../components/fields/LabelField";
// import TeamTable from "../../../components/tables/Bin/TeamTable";
import FullClientTable from "../../../components/tables/Bin/FullClientTable";
import PageLayout from "../../../layouts/PageLayout";
 import data from "../../../data/master/Bin/fullClientCases.json";
 import { getClientData } from "../../../api/endpoints"
 import { useQuery } from "../../../api/query"
import instance from "../../../api/axios";

export default function BlogSection() {
  const [allTeams, setAllTeams] = useState(null)
  const query = useQuery();
  const [allfilterData, setAllfilterData] = useState(null);
  const search = () => {
    if (query !== "" || query !== null) {
     const filterarray = allTeams?.filter((e) => {
      if (e?.email?.toLowerCase().includes(query.toLowerCase())) {
        return e;
      }
      });
      setAllfilterData(filterarray);
    }
  };
  const getTeams=useCallback(async ()=>{
    try {
      await instance({
       url: `${getClientData}/${query.get("query_id")}`,
       method: "GET", 
      },).then((res) => {
        console.log(res.data.data , " ====")
        setAllTeams(res.data.data)
      });
    } catch (e) {
      console.error(e);
    }
  },[])
  useEffect(() => {
    getTeams()
    search()
    return () => {
      
    }
  }, [query])
  

  console.log(allTeams,'all')
  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <CardLayout>
            <Breadcrumb title={data?.pageTitle}>
              {/* {data?.breadcrumb.map((item, index) => (
                <Item key={index} className="mc-breadcrumb-item">
                  {item.path ? (
                    <Anchor className="mc-breadcrumb-link" href={item.path}>
                      {item.text}
                    </Anchor>
                  ) : (
                    item.text
                  )}
                </Item>
              ))} */}
               <Anchor
                  className="mc-breadcrumb-link d-flex flex-row-reverse"
                  href="/add-team-member"
                >
                  Add
                </Anchor>
            </Breadcrumb>
          </CardLayout>
        </Col>

        <Col xl={12}>
          <CardLayout>
            <Row>
              <Col xl={10}>
                {" "}
                {/* <CardHeader title="teams Listings" /> */}
              </Col>
              <Col xl={2}>
                {/* <Anchor
                  className="mc-breadcrumb-link d-flex flex-row-reverse"
                  href="/add-team-member"
                >
                  Add
                </Anchor> */}
              </Col>
            </Row>

            <Row xs={1} sm={4} className="mb-4">
              <Col xl={8} sm={0}>

              </Col>
              {/* {data?.filter.map((item, index) => (
                <Col xl={4} sm={12} key={index}>
                  <LabelField
                    type={item.type}
                    label={item.label}
                    option={item.option}
                    placeholder={item.placeholder}
                    labelDir="label-col"
                    fieldSize="w-100 h-sm"
                    onChange={(e)=> setQuery(e.target.value)}
                  />
                </Col>
              ))} */}
            </Row>
            <FullClientTable
              thead={data?.table.thead}
              tbody={allTeams}
            />
       {/* <Pagination /> */}          </CardLayout>
        </Col>
      </Row>
    </PageLayout>
  );
}

