import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import instance from "../../api/axios";
import { Box, Form, Heading, Button, Anchor, Image, Text } from "../../components/elements";
import IconField from "../../components/fields/IconField";
import Logo from "../../components/Logo";
import data from "../../data/master/login.json";

export default function Login() {
    const [loginData, setLoginData] = useState(null)
    const handleDataOnChange = (e, index)=>{
        if(index===0){
            setLoginData({...loginData, email:e.target.value})
        }
        if(index===1){
            setLoginData({...loginData, password:e.target.value})
        }
       

    }
    useEffect(() => {
      sessionStorage.clear()
    
      return () => {
        
      }
    }, [])
    
    const navigate = useNavigate()
    const loginAdmin=async ()=>{

        try {
          await instance({
            url: "/auth/signin",
            method: "POST",
            data:loginData,
            
          },).then((res) => {
            
           if(res.status===200){
            sessionStorage.clear()
            if(res.data.user.role=="None"){
                sessionStorage.clear()
                return navigate("/login") 
            }
            sessionStorage.setItem("admin_token_validation",res.data.token)
            sessionStorage.setItem("admin_role_validation",res.data.user.role)
            sessionStorage.setItem("admin_email_validation",res.data.user.email)
            sessionStorage.setItem("admin_name_validation",res.data.user.name)
            sessionStorage.setItem("admin_id_validation",res.data.user._id)
            sessionStorage.setItem("admin_img_validation",res.data.user.img)



            
            // toast.success('Login Sucessfully', {
            //     position: "top-right",
            //     autoClose: 2000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,
            //     theme: "light",
            //     });
           }
        //    navigate("/leads-section")
       
           window.location.assign("/leads-section")

          });
        } catch (e) {
            sessionStorage.clear()
            toast.error('Login faild', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
        }
      }
    return (
        <Box className="mc-auth">
            <Image
                src={ data?.pattern.src } 
                alt={ data?.pattern.alt }
                className="mc-auth-pattern"  
            />
            <Box className="mc-auth-group">
                <Logo 
                    src = { data?.logo.src }
                    alt = { data?.logo.alt }
                    href = { data?.logo.path }
                    className = "mc-auth-logo"
                />
                <Heading as="h4" className="mc-auth-title font-extrabold">{ data?.title }</Heading>
                <Form className="mc-auth-form">
                    {data?.input.map((item, index) => (
                        <IconField 
                            key = { index }
                            icon = { item.icon }
                            type = { item.type }
                            option = { item.option }
                            classes = { item.fieldSize }
                            placeholder = { item.placeholder }
                            passwordVisible = { item.passwordVisible }
                            onChange={(e)=> handleDataOnChange(e,index)}
                        />
                    ))}
                    <Button onClick={()=>loginAdmin()} className={`mc-auth-btn ${data?.button.fieldSize} bg-primary`} type={ data?.button.type }>{ data?.button.text }</Button>
                </Form>
            </Box>
            <ToastContainer/>
        </Box>
    );
}