import React from 'react'
import { useEffect } from 'react'
import { Navigate, useLocation } from 'react-router-dom'

const Protected = ({ children }) => {
  let location = useLocation()

  //   const consultantpath = [
  //     "active-admin","editmyacount",
  // "registeradmin",
  // "practicelist",
  // "editpracticearea",
  // "addpractices",
  // "blogsection",
  // "addblog",
  // "editblog",
  // "eventsection",
  // "addevent",
  // "editevent",
  // "edit-cms-about",
  // "ourteams",
  // "add-team-member",
  // "edit-team-member",
  // "leads-section",
  // "new-client-profile",
  // "existing-client-profile",
  // "user-contact",
  // "user-contact-profile",
  // "ceo-message",
  // "add-certificate",
  // "today-consultant",
  // "page-intro"
  // ]
  const receptionistpath = ["/existing-client-profile", "/editmyacount",
    "/new-client-profile", "/leads-section", "/today-consultant", "/existing-leads-section", "/user-contact"]

  const consultantpath = ["/existing-client-profile", "/editmyacount",
    "/new-client-profile", "/leads-section", "/existing-leads-section"]
  const papath = ["/existing-client-profile", "/editmyacount",
    "/new-client-profile", "/-section", "/existing-leads-section"]

  //   if(!receptionistpath.includes(location.pathname)){
  //     console.log("ha")
  //     return <Navigate to= "/"/>
  //   }else{
  //     console.log("na")
  //     // return <Navigate to= "/login"/>

  //   }

  const isSignIn = sessionStorage.getItem("admin_token_validation")
  const role = sessionStorage.getItem("admin_role_validation")


  if (!isSignIn || isSignIn == "undefine") {
    return <Navigate to="/login" />
  }
  if (role == "HR" || role == "Receptionist") {
    if (!receptionistpath.includes(location.pathname)) {
      return <Navigate to="/leads-section" />
    }
  }
  if (role == "Consultant") {
    if (!consultantpath.includes(location.pathname)) {
      return <Navigate to="/leads-section" />
    }
  }
  if (role == "PA") {
    if (!papath.includes(location.pathname)) {
      return <Navigate to="/leads-section" />
    }
  }

  return children
}

export default Protected