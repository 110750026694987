import React, { useEffect } from "react";
import MenuItem from "./MenuItem";
import { List, Menu, Heading } from "../elements";
import { useState } from "react";

export default function MultipleMenu({ data }) {
    const [currentRole, setCurrentRole] = useState(null)
    useEffect(() => {
        setCurrentRole(sessionStorage.getItem("admin_role_validation"))
    }, [currentRole])

    return (
        <>
            {data?.map((item, index) => (

                <Menu key={index} className="mc-sidebar-menu">

                    <Heading as="h5" className="mc-sidebar-menu-title">{item.title}</Heading>
                    <List className="mc-sidebar-menu-list">
                        {item.menu.map((item, index) => (
                            <>
                                {currentRole == "Super Admin" &&


                                    <MenuItem
                                        key={index}
                                        item={item}
                                    />


                                }
                                {currentRole == "CEO" &&
                                    <>
                                        {
                                            item.text == "employee" || item.text == "lead" || item.text == "practice area" || item.text == "blogs" ?
                                                <MenuItem
                                                    key={index}
                                                    item={item}
                                                /> : null
                                        }
                                    </>
                                }
                                {currentRole == "HR" &&
                                    <>
                                        {
                                            item.text == "lead" || item.text == "Manage slots" || item.text === "contact us" ?
                                                <MenuItem
                                                    key={index}
                                                    item={item}
                                                /> : null
                                        }
                                    </>
                                }
                                {currentRole == "Receptionist" &&
                                    <>
                                        {
                                            item.text == "lead" || item.text == "Manage slots" || item.text === "contact us" ?
                                                <MenuItem
                                                    key={index}
                                                    item={item}
                                                /> : null
                                        }
                                    </>
                                }
                                {currentRole == "PA" &&
                                    <>
                                        {
                                            item.text == "lead" ?
                                                <MenuItem
                                                    key={index}
                                                    item={item}
                                                /> : null
                                        }
                                    </>
                                }
                                {currentRole == "None" &&
                                    <>
                                        {
                                            item.text == "lead" || item.text == "CMS" ?
                                                <></> : null
                                        }
                                    </>
                                }
                                {currentRole == "Consultant" &&
                                    <>
                                        {

                                            item.text == "lead" ?
                                                <MenuItem
                                                    key={index}
                                                    item={item}
                                                /> : null

                                        }
                                    </>
                                }
                            </>

                        ))}
                    </List>
                </Menu>
            ))}
        </>
    );
}