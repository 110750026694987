import axios from "axios"; 
let token  = sessionStorage.getItem("admin_token_validation")

const instance = axios.create({
  baseURL:"https://api.binhaider.amazing7studios.com",
  // baseURL:"http://10.0.0.58:3000",
  // baseURL:"http://localhost:4000",


  headers:{
    Authorization:`Bearer ${token}`,
    timeout:1000,
  }
})

export default instance;