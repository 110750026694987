import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { DuelText, RoundAvatar } from "..";
import { Anchor, Button } from "../elements";
import { adminLogout } from "../../api/adminlogout";

export default function ProfileDropdown({ name, username, image, dropdown }) {
  
  return (
    <Dropdown className="mc-header-user">
      <Dropdown.Toggle className="mc-dropdown-toggle">
          <RoundAvatar src={image} alt="avatar" size="xs" />
        <DuelText title={`${sessionStorage.getItem("admin_role_validation")}`} descrip={`@${sessionStorage.getItem("admin_name_validation").slice(0,10)}...`} size="xs" />
      </Dropdown.Toggle>
      <Dropdown.Menu align="end" className="mc-dropdown-paper">
        <Anchor
          href={`/editmyacount?query_id=${sessionStorage.getItem(
            "admin_id_validation"
          )}&user_name=${sessionStorage.getItem("admin_name_validation")}`}
          icon={"person"}
          text={"my account"}
          className="mc-dropdown-menu"
        />
        <Button
          icon={"lock"}
          text={"logout"}
          className="mc-dropdown-menu"
          onClick={adminLogout}
        />
      </Dropdown.Menu>
    </Dropdown>
  );
}
