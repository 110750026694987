import React, { useCallback, useEffect, useState } from "react";
import { Row, Col, Tab, Tabs } from "react-bootstrap";
import {
    Item,
    Anchor,
} from "../../../../../components/elements";
import { CardLayout, TabCard } from "../../../../../components/cards";
import { Breadcrumb, FileUpload } from "../../../../../components";
import PageLayout from "../../../../../layouts/PageLayout";
import data from "../../../../../data/master/Bin/CMS/Subscriber/subscribe.json";
import { useQuery } from "../../../../../api/query";
import instance from "../../../../../api/axios";
import { getpageintroapi, updatepageintroapi } from "../../../../../api/endpoints";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import SubscribeTable from "../../../../../components/tables/Bin/SubscribeTable";

export default function Subscribe() {
  const [allemails, setallemails] = useState(null);
  const [query, setQuery] = useState("");
  const [allfilterData, setAllfilterData] = useState(null);
  const search = () => {
    if (query != "" || query != null) {
      const filterarray = allemails?.filter((e) => {
        if (e.en.title.toLowerCase().includes(query.toLowerCase())) {
          return e;
        }
      });
      setAllfilterData(filterarray);
    }
  };
  const getemail = useCallback(async () => {
    try {
      await instance({
        url: "/subscribe/all-email",
        method: "GET",
      }).then((res) => { 
        setallemails(res.data);
      });
    } catch (e) {
      console.error(e);
    }
  }, []);
  useEffect(() => {
    getemail();
    search();
    return () => {};
  }, [query]);

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <CardLayout>
            <Breadcrumb title={data?.pageTitle}>
              {data?.breadcrumb.map((item, index) => (
                <Item key={index} className="mc-breadcrumb-item">
                  {item.path ? (
                    <Anchor className="mc-breadcrumb-link" href={item.path}>
                      {item.text}
                    </Anchor>
                  ) : (
                    item.text
                  )}
                </Item>
              ))}
            </Breadcrumb>
          </CardLayout>
        </Col>

        <Col xl={12}>
          <CardLayout>
            <Row>
              <Col xl={10}>
                {" "}
                {/* <CardHeader title="All blogs" /> */}
              </Col>
              <Col xl={2}>
                {/* <Anchor
                  className="mc-breadcrumb-link d-flex flex-row-reverse"
                  href="/addblog"
                >
                  Add
                </Anchor> */}
              </Col>
            </Row>

            <Row xs={1} sm={4} className="mb-4">
              <Col xl={8} sm={0}></Col>
              
            </Row>
            <SubscribeTable
              thead={data?.table?.thead}
              tbody={query ? allfilterData : allemails}
            />
       {/* <Pagination /> */}          </CardLayout>
        </Col>
      </Row>
      <ToastContainer />
    </PageLayout>
  );
}
