import React, { useCallback, useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { List, Item, Icon, Text, Box, Anchor, Button, Option } from "../../../components/elements";
import { Breadcrumb, RoundAvatar, DivideTitle, DuelText } from "../../../components";
import { CardLayout, CardHeader, FloatCard, ActivityCard } from "../../../components/cards";
import PageLayout from "../../../layouts/PageLayout";
import data from "../../../data/master/Bin/newClientProfile.json";
import { getNewClient, updateNewClientAPI, updateRemainingFees, changeActiveStatus } from "../../../api/endpoints";
import { useQuery } from "../../../api/query"
import { Td } from "../../../components/elements/Table";
import instance from "../../../api/axios";
import { LegendField, LegendTextarea } from "../../../components/fields";
import { toast, ToastContainer } from "react-toastify";
import { MdDragHandle } from "react-icons/md";
import DataInfo from "../../../data/master/Bin/newClientProfile.json";

// import MdPayments from 'react-icons/md'

export default function NewClientProfile() {
    const query = useQuery();
    const [ischeckButtonclicked, setischeckButtonclicked] = useState(false);
    const [isAmountButtonClicked, setIsAmountButtonClicked] = useState(false);
    const [istotalpaidmoney, settotalBalance] = useState(true);
    // const [buttonClicked, setButtonClicked] = useState(false);
    const [userProfile, setUserProfile] = useState(null)
    const [remainingBal, setremainingBalance] = useState(false);

    const [singleStatus, setSingleStatus] = useState(null);
    const [openMessage, setOpenMessage] = useState(false)
    const [isAdminMessage, setIsAdminMessage] = useState(null);
    const [isConsultantMessage, setIsConsultantMessage] = useState(null)
    const [defaultStatus, setDefaultStatus] = useState({
        label: ""
    })
    console.log(defaultStatus, 'pending')
    const [currentUserRole, setCurrentUserRole] = useState(sessionStorage.getItem("admin_role_validation"))
    const getNewUser = useCallback(async () => {
        try {
            await instance({
                url: `${getNewClient}/${query.get("query_id")}`,
                method: "GET",
            },).then((res) => {

                setUserProfile(res.data)
                console.log(res.data, "vvaau")
                setDefaultStatus({
                    label: res.data.status
                })
                setIsAdminMessage(res.data.adminmessage)
                setIsConsultantMessage(res.data.consultantmessage)
                console.log(res.data.status, "status")
            });
        } catch (e) {
            console.error(e);
        }
    }, [])
    const updateNewUser = async () => {
        try {
            await instance({
                url: `${updateNewClientAPI}/${query.get("query_id")}`,
                method: "PUT",
                data: userProfile,
            }).then((res) => {
                window.location.reload()

            });

        } catch (e) {
            toast.error('Something Went Wrong.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    //   this api use for the data update through the remaining balance
    const updateRemaining = async () => {

        try {
            console.log(data, '===')
            await instance({
                url: `${updateRemainingFees}/${query.get("query_id")}`,
                method: "PUT",
                data: remainingBal,

            }).then((res) => {
                console.log("eee", res.data)
                window.location.reload()

            });
        } catch (e) {
            console.log("err", e)

            toast.error('Something Went Wrong.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    const updateConNewUser = async () => {
        try {
            await instance({
                url: `${updateNewClientAPI}/${query.get("query_id")}`,
                method: "PUT",
                data: userProfile,
            }).then((res) => {
                window.location.reload()

            });
        } catch (e) {
            toast.error('Something Went Wrong.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }
    useEffect(() => {
        getNewUser();
    }, [])

    const changeActioStatus = async (e) => {
        const newLabel = e.target.value;
        setDefaultStatus({ label: newLabel }); 
        console.log("Updated Default Status: ", defaultStatus);
        
        try {
            await instance({
                url: `${changeActiveStatus}/${query.get("query_id")}`,
                method: "POST",
                data: { status: newLabel }, 
            }).then((res) => {
                console.log("Status has been changed");
            });
        } catch (e) {
            console.error("Error updating status:", e);
        }
    }
    
    


    const changeStatus = async () => {
        console.log("Change Status Function Called");
        console.log("New Status Label: ", defaultStatus.label);
    
        try {
            await instance({
                url: `${changeActiveStatus}/${query.get("query_id")}`,
                method: "POST",
                data: { status: defaultStatus.label },
            }).then((res) => {
                console.log("status has benn changed")
            });
        } catch (e) {
        }
    }





    const handleBalanace = (event) => {
        const { name, value } = event.target;
        setremainingBalance((preValue) => ({
            ...preValue,
            [name]: value
        }));
    }

    const handlecheckButton = () => {
        setischeckButtonclicked(true);
    }

    const handleAmountButton = () => {
        setIsAmountButtonClicked(true)
    }

    const handlepaidButton = () => {
        settotalBalance(!istotalpaidmoney);
        setremainingBalance(false);


    }

    const handleRemainingButton = () => {
        setremainingBalance(!remainingBal);
        settotalBalance(false);
    }
    // const handleStatus = (e) => {
    //     setSingleStatus({ ...userProfile, status: e.target.value });
    //     console.log("singleStatus:", singleStatus);
    // }

    return (
        <PageLayout>
            <Row>
                <Col xl={12}>
                    <CardLayout>
                        <Breadcrumb title="Client Query">

                        </Breadcrumb>

                    </CardLayout>
                </Col>

                <Row>
                    <Col xl={12}>
                        <CardLayout>
                            <Box className="mc-user-group">
                                <Row xl={12}>
                                    <Col xl={6}>
                                        <Box className="mc-user-profile">

                                            <DuelText
                                                title={`${userProfile?.name}`}
                                                // descrip={ userProfile?.createdAt } 

                                                size={data?.profile.size}
                                            />
                                        </Box>
                                        <Box className="mb-4">
                                            {/* <DivideTitle title="communication" className="mb-4" /> */}
                                            <List className="mc-user-metalist">

                                                <Item >
                                                    <Icon>{'phone_in_talk'}</Icon>
                                                    <Text as="span">{userProfile?.mobileNumber}</Text>
                                                </Item>
                                                <Item >
                                                    <Icon>{'feed'}</Icon>
                                                    <Text as="span">{userProfile?.email}</Text>
                                                </Item>
                                                <Item >
                                                    <Icon>{'event_note'}</Icon>
                                                    <Text as="span">
                                                        {userProfile?.slotdateAndTime}
                                                    </Text>
                                                </Item>
                                                <Item >
                                                    <Icon>{'layers'}</Icon>
                                                    <Text as="span">{userProfile?.expertise}</Text>
                                                </Item>

                                                {/* <Td  title={ userProfile?.status }>
                                { userProfile.status === "open" && <Text className="mc-table-badge green">{ userProfile?.status }</Text> }
                                { userProfile.status === "close" && <Text className="mc-table-badge red">{ userProfile?.status }</Text> }
                                 </Td> */}
                                                <Item >
                                                    <Icon>{'map'}</Icon>
                                                    {/* <MdPayments className="mr-2" /> */}
                                                    <Text as="span">{userProfile?.totalpaidmoney.map((payment, index) => (
                                                        <div key={index} className="mb-2">
                                                            {/* <p>Payment {index + 1}</p> */}
                                                            <p>Amount: {payment.amount}</p>
                                                            <p>Message: {payment.message}</p>
                                                        </div>
                                                    ))}</Text>

                                                </Item>

                                            </List>
                                        </Box>
                                        {/* <Box className="mb-4">
                                            <DivideTitle title={data?.bio.title} className="mb-3" />
                                            <Text className="mc-user-bio mb-4">{userProfile?.message}</Text>
                                        </Box> */}
                                    </Col>

                                    <Col xl={6}>

                                        {/* <DivideTitle title={data?.biorep.title} className="mb-3" /> */}
                                        {/* <LegendField
                                            title={data?.status?.title}
                                            // value={postData?.en?.status}
                                            option={data?.status?.option}
                                            activeOption={userProfile?.updatestatusbyadmin}

                                            onChange={(e) => {
                                                // updatestatusbyadmin
                                                setUserProfile({ ...userProfile, updatestatusbyadmin: e.target.value });

                                            }}
                                        /> */}
                                        <div className="mb-4" />

                                        <Col xl={25}>
                                            <LegendField
                                                title={data?.status.title}
                                                value={defaultStatus?.label}
                                                option={data?.status.option}
                                                onChange={changeActioStatus}
                                            />
                                        </Col>

                                        {/* <div className="mb-4" />
                                        {
                                            currentUserRole == "Receptionist" || currentUserRole == "HR" || currentUserRole == "PA" ?
                                                <LegendTextarea
                                                    title={data?.biorep.title}
                                                    fieldSize={'h-xl w-100'}
                                                    value={userProfile?.adminmessage}
                                                    onChange={(e) => {
                                                        setUserProfile({ ...userProfile, adminmessage: e.target.value })

                                                    }}
                                                    placeholder="Type here..."
                                                   

                                                /> :
                                                <LegendTextarea
                                                    title={data?.biorep.title}
                                                    fieldSize={'h-xl w-100'}
                                                    value={userProfile?.adminmessage}
                                                    placeholder="Type here..."
                                                   

                                                />
                                        }

                                        <div className="mb-4" />
                                        {
                                            currentUserRole == "Consultant" || currentUserRole == "CEO"?
                                                <LegendTextarea
                                                    title={data?.biocon.title}
                                                    fieldSize={'h-xl w-100'}
                                                    value={userProfile?.consultantmessage}
                                                    onChange={(e) => {
                                                        setUserProfile({ ...userProfile, consultantmessage: e.target.value })
                                                    }}
                                                    placeholder="Type here..."
                                                    
                                                /> :
                                                <LegendTextarea
                                                    title={data?.biocon.title}
                                                    fieldSize={'h-xl w-100'}
                                                    value={userProfile?.consultantmessage}
                                                    placeholder="Type here..."
                                                    

                                                />
                                        } */}
                                        {!isAmountButtonClicked &&
                                            <button type='submit' onClick={handleAmountButton}
                                                className="mt-4 heading-text  underline-black font-bold-400 text-primary "
                                                style={{ textDecoration: 'underline' }}
                                            >
                                                Add New Invoices
                                            </button>
                                        }
                                        {isAmountButtonClicked &&
                                            <>
                                                {
                                                    isAdminMessage ? <></> :
                                                        currentUserRole == "Receptionist" || currentUserRole == "HR" || currentUserRole == "PA" ?
                                                            <Button
                                                                className="mc-btn primary mt-3"
                                                                icon="verified"
                                                                text="Save"
                                                                onClick={() => updateNewUser()}
                                                            /> : <></>
                                                }

                                                {
                                                    isConsultantMessage ? <></> :
                                                        currentUserRole == "Consultant" || currentUserRole == "CEO" ?
                                                            <Button
                                                                className="mc-btn primary mt-3"
                                                                icon="verified"
                                                                text="Save"
                                                                onClick={() => updateConNewUser()}
                                                            /> : <></>
                                                }

                                                <div className="mb-4" />
                                                {
                                                    currentUserRole == 'Super Admin' || currentUserRole == "HR" || currentUserRole == "Receptionist" ?
                                                        <LegendTextarea
                                                            title={data?.bioconi.title}
                                                            fieldSize={'h-xl w-100'}
                                                            value={remainingBal.message}
                                                            onChange={handleBalanace}
                                                            name="message"
                                                            placeholder="Write Message..."

                                                        /> :
                                                        <LegendTextarea
                                                            title={data?.bioconi.title}
                                                            fieldSize={'h-xl w-100'}
                                                            value={remainingBal.message}
                                                            placeholder="Write Message..."


                                                        />
                                                }

                                                <div className="mb-4" />
                                                {
                                                    currentUserRole == 'Super Admin' || currentUserRole == "HR" || currentUserRole == "Receptionist" ?
                                                        <LegendTextarea
                                                            title={data?.biocons.title}
                                                            fieldSize={'h-xl w-100'}
                                                            value={remainingBal.amount}
                                                            name="amount"

                                                            onChange={handleBalanace}
                                                            placeholder="Amount...."

                                                        /> :
                                                        <LegendTextarea
                                                            title={data?.biocons.title}
                                                            fieldSize={'h-xl w-100'}
                                                            value={remainingBal.amount}
                                                            placeholder="Amount...."


                                                        />
                                                }
                                                <button
                                                    type="submit"
                                                    onClick={() => updateRemaining()}

                                                    className=" mt-4 btn btn-primary w-32  font-bold bg-blue-500 hover:bg-blue-600 hover:text-black text-white rounded ml-[200px]"
                                                >
                                                    Save
                                                </button>
                                            </>
                                        }
                                    </Col>
                                </Row>
                                <Row xl={12}>
                                    <Box className="mb-4">
                                        <DivideTitle title={data?.bio.title} className="mb-3" />
                                        <Text className="mc-user-bio mb-4 text-break">{userProfile?.message}</Text>
                                    </Box>
                                </Row>
                            </Box>
                            <Box>
                                {!ischeckButtonclicked &&
                                    <button type='submit' onClick={handlecheckButton}
                                        className="heading-text  underline-black font-bold-400 text-primary "
                                        style={{ textDecoration: 'underline' }}
                                    >
                                        Check Balance
                                    </button>
                                }
                                {ischeckButtonclicked &&
                                    <>
                                        <div className="d-flex gap-4">
                                            <button
                                                type="submit"
                                                onClick={handlepaidButton}
                                                className={`btn btn-primary w-32 font-bold ${istotalpaidmoney ? 'bg-blue-500 text-white fixed' : 'bg-white text-black'
                                                    } hover:bg-blue-600 hover:text-black fixed rounded ml-[200px] `}
                                            >
                                                Paid Invoices
                                            </button>
                                            <button
                                                type="submit"
                                                onClick={handleRemainingButton}
                                                className={`btn btn-primary w-32 font-bold ${remainingBal ? 'bg-blue-500 text-white fixed' : 'bg-white text-black fixed'
                                                    } hover:bg-blue-600 hover:text-black fixed rounded ml-[200px] `}
                                            >
                                                Unpaid Invoices
                                            </button>
                                        </div>
                                        {istotalpaidmoney && userProfile?.totalpaidmoney?.length > 0 ? (

                                            <table className="table-auto w-full">
                                                <thead>
                                                    <tr className="bg-gray-200">
                                                        <th className="py-2">Amount</th>
                                                        <th className="px-4 py-2">Message</th>
                                                        <th className="px-4 py-2">Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {/* {userProfile.remainingbalance.map((elem,index)=>{
        <ul key={elem}>
            <li>
{userProfile.remainingbalance.amount}
            </li>
        </ul>
      })} */}
                                                    {userProfile?.totalpaidmoney?.map((item, index) => (
                                                        <tr key={index} className={index % 2 === 0 ? 'bg-gray-100' : ''}>
                                                            <td className=" py-2">{item?.amount}</td>
                                                            <td className="px-4 py-2">{item?.message}</td>
                                                            <td className="px-4 py-2">{item?.date}</td>
                                                        </tr>
                                                    ))}

                                                </tbody>
                                            </table>
                                        ) : istotalpaidmoney ? (
                                            <div className=" pt-4  mb-6">
                                                <p className="text-black bg-true-gray-900">
                                                    No Total Paid Money found.
                                                </p>
                                            </div>
                                        ) : null}

                                         {remainingBal && userProfile?.remainingbalance?.length > 0 ? (
                                            <table className="pt-4 table-auto w-full">
                                                <thead>
                                                    <tr className="bg-gray-200">
                                                        <th className=" py-2">Amount</th>
                                                        <th className="px-4 py-2">Message</th>
                                                        <th className="px-4 py-2">Date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {/* {userProfile.remainingbalance.map((elem,index)=>{
        <ul key={elem}>
            <li>
{userProfile.remainingbalance.amount}
            </li>
        </ul>
      })} */}
                                                    {userProfile?.remainingbalance?.map((item, index) => (
                                                        <tr key={index} className={index % 2 === 0 ? 'bg-gray-100' : ''}>
                                                            <td className=" py-2">{item?.amount}</td>
                                                            <td className="px-4 py-2">{item?.message}</td>
                                                            <td className="px-4 py-2">{item?.date}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        ) : remainingBal ? (
                                            <div className=" pt-4  mb-6">
                                                <p className="text-black bg-true-gray-900">
                                                    No Remaining Balance found.
                                                </p>
                                            </div>
                                        ) : null}

                                    </>
                                }
                            </Box>

                        </CardLayout>
                    </Col>

                </Row>
                <ToastContainer />
            </Row>
        </PageLayout>
    )
}