export const getAboutCMS = "/cmsaboutcontent/get-about-content"


//user Slot Booking

export const slotUserPOST = "/user/post-message"
export const slotAllUsers = "/user/all-message"
export const slotAllExistingUsers = "/existing-user/all-message"
export const getNewClient = "/user/get-message"
// export const getClientData = "/userauthor/getdata"
export const getClientData = "user/getCaeseByids" 
export const updateNewClientAPI = "/user/update-client"
export const updateRemainingFees = "/user/updatedata"
export const changeActiveStatus = "/user/saveCaseStatus"
//existing -
export const getExistingClient = "/existing-user/get-message"

//contact User
export const getAllMessage = "/contact/all-contact"
export const getSingleMessage = "/contact/single-contact"

//
export const allTodayConsultant = "/today-active-consultant/get-all-status"
export const updateTodayConsultant = "/today-active-consultant/update-status"
export const singletodayconsultant = "/today-active-consultant/get-single-status"


export const updateorsavetodayconsultant = "/today-active-consultant/add-or-update"
export const todayconsultantbyid = "/today-active-consultant/get-today-consultant-id"

export const alltodayconactiveinactive = "/today-active-consultant/get-consultant-by-today-status"


//Introduction

export const getpageintroapi = "/page-intro/find"
export const updatepageintroapi = "/page-intro/update"

